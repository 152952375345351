/**
 * Helper methods related to file handling and manipulation.
 * This file provides utility functions for working with files, including:
 * - Converting a data URI string into a Blob object using `dataUriToBlob`.
 * - Converting a File object into a base64-encoded string or extracting bytes without data type using `convertToBase64`.
 */

/**
 * Converts a data URI string into a Blob object.
 * @param {string} dataUri - The data URI string to convert.
 * @returns {Blob} The Blob object representing the data from the data URI.
 */
export const dataUriToBlob = function (dataUri) {
  const splitDataURI = dataUri.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

/**
 * Converts a file into a base64-encoded string or extracts bytes without data type.
 * @param {File} file - The File object to convert.
 * @param {boolean} withoutType - If true, returns only the bytes of the file without data type.
 * @returns {Promise<string>} A Promise that resolves to the base64 or bytes of the file.
 */
export const convertToBase64 = function (file, withoutType = false) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (!withoutType) {
        resolve(reader.result)
      }
      else {
        const dataUrlParts = reader.result.split(',')
        if (dataUrlParts.length === 2) {
          resolve(dataUrlParts[1])
        }
        else {
          reject(new Error('Invalid data URL format'))
        }
      }
    }
    reader.onerror = error => reject(error)
    reader.readAsDataURL(file)
  })
}

/**
 * Converts a file into a base64-encoded string or extracts bytes without data type.
 * @returns {Promise<Blob>} A Promise that resolves to the base64 or bytes of the file.
 * @param imagePath
 */
export const convertFromPathToBlob = async function (imagePath = '') {
  if (imagePath) {
    const response = await fetch(imagePath)
    return await response.blob()
  }
}
